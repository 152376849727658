html {
  --banner-bg-color: #193a71;

  scroll-snap-type: y mandatory;
  /* height: 100vh; */
  padding: 0;
  margin: 0;
  /* overflow: hidden; */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #000000;

  position: relative;
  /* height: 100vh; */
  height: 100%;

  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin-bottom: 3rem;
}

/* #scroll-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  box-shadow: inset 0px 0px 0px 2px green;
} */

/* #scroll-box {
  position: relative;
  width: 100vw;
  height: 80vh;
  overflow: scroll;

  scroll-snap-type: y mandatory;
} */

/* #scroll-top-border {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: calc(env(safe-area-inset-top, 0vh) + 10vh);
  pointer-events: none;
  box-shadow: inset 0px 0px 0px 5px red;
  z-index: 9999;
} */

/* #scroll-top-border rect {
  fill: #ff000050;
} */

/* #scroll-bottom-border {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: calc(env(safe-area-inset-bottom, 0vh) + 10vh);
  pointer-events: none;
  box-shadow: inset 0px 0px 0px 5px orange;
  z-index: 9999;
} */

/* #scroll-bottom-border rect {
  fill: #00ff0050;
} */

#full-container {
  padding-bottom: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* #image-container {
  position: relative;
  display: inline-flex;
  max-width: 100%;
  max-height: 100%;

  padding-bottom: 5vh;
  overflow: hidden;
} */

/* #throw-no-ball-transparent {
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
} */

/* #throw-no-ball {
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  position: absolute;
} */

#ball {
  /* max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0; */

  /* animation: indefinite-spin 30s infinite linear; */
  /* transform-origin: 26.3% 22.2%; */
}

#image-container #banner {
  max-width: 100%;
  width: 90%;
  height: 20%;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -15%);
  /* box-shadow: inset 0px 0px 0px 10px lightcoral; */
}

#banner-background {
  width: 90%;
  height: 100%;
  margin-inline: auto;
  /* transform: skew(-20deg); */
  background-color: var(--banner-bg-color);
  background: linear-gradient(-45deg, var(--banner-bg-color) 40%, #9f1c92 80%);
  filter: drop-shadow(10px 10px 10px #000000);
}

#banner-background-dark {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -0%);
  background-color: rgb(0, 0, 0, 0.8);
  filter: drop-shadow(10px 10px 10px #000000);
}

#svg-letter-m,
#svg-letters-adison {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10%;
  left: -8%;
  stroke: #fff;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-linecap: round;
  /* filter: drop-shadow(0.25em 0.25em 0.25em #000000); */
  /* box-shadow: inset 0px 0px 0px 7px orange; */
}

#svg-letters-olguin {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 35%;
  left: 32%;
  stroke: #fff;
  stroke-width: 1;
  stroke-linejoin: round;
  stroke-linecap: round;
  /* filter: drop-shadow(0.25em 0.25em 0.25em #000000); */
  /* box-shadow: inset 0px 0px 0px 4px silver; */
}

.scroll-container {
  height: 100vh;

  /* padding: 15% 0% 15% 0%; */
  /* padding: calc(env(safe-area-inset-top, 0vh) + 10vh) env(safe-area-inset-right)
    calc(env(safe-area-inset-bottom, 0vh) + 10vh) env(safe-area-inset-left); */

  /* Moved top and bottom padding to ScrollOverlay. Keeping padding for left and right for landscape modes */
  padding: 0vh env(safe-area-inset-right, 0vh) 0vh
    env(safe-area-inset-left, 0vh);

  overflow: hidden;
  position: relative;

  scroll-snap-align: start;
  /* scroll-snap-stop: always; */
}

.height-test-env {
  height: 100vh;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);

  display: relative;
}

.page-container {
  display: block;
  position: relative;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  /* box-shadow: inset 0px 0px 2px 2px green; */
}

.content-container {
  width: 100%;
  height: 100%;
  position: relative;
  /* box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 0.5); */
}

.qrcode-box {
  position: relative;
  display: inline-flex;
  max-width: 100%;
  max-height: 100%;
  /* padding-bottom: 5vh; */
  overflow: hidden;
}

.qrcode-box img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  /* object-fit: contain; */
  border-radius: 1rem;
}

/* ----------------- */
.install-popover {
  height: auto;
  width: min(18rem, 80vw);
  max-width: 80vw;
  transform: translateY(-3.5rem);
  padding-top: 3rem;
  padding-bottom: 0.5rem;
}

/* This can be reused for other elements. */
/* Arrow was intended for Apple Mobile Safari only. However, iPone in landscape and possibly iPad in portrait and landscape have the context menu button at the top. Opting not to use the arrow until future updates that determine device and orientation. */
/* .add-bottom-arrow::after {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 2em;
  height: 2em;
  content: ""; */
/* using 52% for y axis. When elements are flush against each other, a 1px gap can appear depending on how much the browser is zoomed in and out due to rounding of %. */
/* transform: translate(-50%, -52%) rotate(45deg);
  border-top: 1em solid transparent;
  border-left: 1em solid transparent;
  border-bottom: 1em solid white;
  border-right: 1em solid white;
} */

.install-popover .install-logo {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.375rem;
  width: 4rem;
  max-width: 75%;
  aspect-ratio: 1;
  /* Gives a pop-up dimension look to the image */
  box-shadow: inset 0.1em 0.1em 0.75em -0.5em white,
    inset -0.25em -0.25em 0.625em -0.375em black,
    0.25em 0.25em 0.5em -0.25em black;
  /* background-image: url("./assets/images/logo/logo-128x128.png"); */
  background-size: contain;
  background-repeat: no-repeat;
}

.install-popover .install-header {
  /* height: 3rem; */
  /* border-top-left-radius: 0.5rem; */
  /* border-top-right-radius: 0.5rem; */
  border-bottom: 1px solid black;
}

.install-popover .fst-italic {
  font-size: 0.75rem;
}

.add-home-box {
  /* font-size: 0.5rem; */
  /* font-size: 1.869vw; */
  font-size: min(0.5rem, 1.869vw);
  /* line-height: 0.75rem; */
  /* line-height: 3.044vw; */
  line-height: 100%;
  margin-right: 1rem;
  background-color: rgba(191, 191, 191, 0.1);
}

.add-home-box .add-home-icon {
  /* min-width: 12vw; */
  padding: min(0.25rem, 1.244vw);
}

.add-home-box .add-home-text div {
  /* padding: 0.25rem; */
  /* padding: 1.244vw; */
  padding: min(0.25rem, 1.244vw);
}

.add-home-box img {
  /* width: 3rem; */
  /* width: 8.22vw; */
  width: min(3rem, 8.22vw);
  height: auto;
  /* border-radius: 0.375rem; */
  /* border-radius: 1.7vw; */
  border-radius: min(0.375rem, 1.7vw);
  /* margin: min(0.25rem, 5vw); */
}

/* ----------------- */

/* 
#svg-letters-adison {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -10%;
  left: -10%;
  box-shadow: inset 0px 0px 0px 2px yellow;
} */

/* ----------------- */
@keyframes indefinite-spin {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}

.throwball-box {
  width: 100%;
  /* Set the width of the container */
  height: 100%;
  /* Set initial height to 0 for aspect ratio calculation */
  /* padding-bottom: 60%; */
  /* Set the desired aspect ratio (e.g., 60% for a 3:2 aspect ratio) */
  position: relative;
  /* Create a positioning context for the image */
  overflow: hidden;
  box-shadow: inset 0px 0px 0px 2px rgb(255, 255, 255, 0.5);
}

.throwball-box .animation-image {
  /* max-height: 100%; */
  /* box-shadow: inset 0px 0px 2px 2px purple; */
  box-shadow: inset 0px 0px 0px 2px rgb(0, 255, 0, 0.5);

  position: absolute; /* Position the image relative to the container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  /* Let the browser calculate the width while maintaining aspect ratio */
  height: auto;
  /* Let the browser calculate the height while maintaining aspect ratio */
  max-width: 100%;
  /* Limit the width to the container's width */
  max-height: 100%; /* Limit the height to the container's height */
}

.throwball-box .hidden-img {
  /* max-height: 100%; */
  /* box-shadow: inset 0px 0px 2px 2px purple; */
  box-shadow: inset 0px 0px 0px 2px rgb(0, 255, 0, 0.5);

  position: absolute; /* Position the image relative to the container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  /* Let the browser calculate the width while maintaining aspect ratio */
  height: auto;
  /* Let the browser calculate the height while maintaining aspect ratio */
  max-width: 100%;
  /* Limit the width to the container's width */
  max-height: 100%; /* Limit the height to the container's height */
}

/* .throwball-box #bball {
  top: 50%;
  left: 50%;
  width: 25%;
  transform: translate(-72%, -87.5%);
} */

/* .img-dim {
  margin-inline: auto;
  box-shadow: inset 0px 0px 0px 4px rgb(255, 0, 0, 1);
  padding: 10%;
} */

.about-bg-img {
  position: absolute;
  top: 0;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: radial-gradient(
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 1) 80%
    ),
    url("./assets/images/backgrounds/sitting-w-ball-vert-bg-534x800.jpg");
  background-position: center 40%;
  background-repeat: no-repeat;
  background-size: cover;
  /* box-shadow: inset 0px 20px 10px -10px rgba(0, 0, 0, 0.8); */
  box-shadow: inset 0px 0px 1rem 1rem rgba(0, 0, 0, 0.8),
    0px 0px 2px 2px rgba(0, 0, 0, 0.8);
  /* border-radius: 0%; */
}

.banner-row {
  position: relative;
  margin-inline: auto;
  margin-top: -100px;
  box-shadow: inset 0px 0px 2px 2px orange;
}

.banner-box {
  width: 80vw;
  max-width: 300px;
  max-height: 20vh;
  margin-inline: auto;
  position: relative;
}

.banner-bg {
  width: 80vw;
  max-width: 350px;
  background-color: var(--banner-bg-color);
  filter: drop-shadow(10px 10px 10px #000000);
  position: absolute;
  left: 50%;
}

.banner-content {
  position: absolute;
  max-height: 20vh;
  filter: drop-shadow(4px 4px 2px #000000);
}

#madison {
  top: -15%;
  left: -5%;
  /* width: 95%; */
}

#letter-m {
  top: -15%;
  left: -5%;
}

.letter-m-transparent {
  position: relative;
  max-height: 20vh;
  max-width: 280px;
}

.svg-icon-link {
  /* background-color: #e91d00; */
  display: inline-block;
  width: 5em;
  height: 5em;
  padding-top: 5em;
  border-radius: 0.5em;
}

.svg-icon-link:hover {
  transform: translate(2px, -2px);
}

.btn {
  line-height: 1em !important;
}

.btn svg {
  margin-bottom: 0.1em;
  /* margin-left: 0.1em;
  margin-right: 0.1em; */
}

.icon-button {
  font-size: 2rem;
  line-height: 1rem;
  opacity: 90%;
}

.icon-button:hover {
  opacity: 100%;
}

.icon-button svg {
  pointer-events: none;
}

/* --------------- */
/* --------------- */

/* QRCODE MODAL */
/* full screen modal translates from top which does not center the qrcode image. This will scale from 0 to 1 instead. Since the element is full screen, the scale will originate from the center of the screen.*/
.qrcode-modal {
  z-index: 9999;
}
.qrcode-modal.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: scale(0);
}

.qrcode-modal.modal.show .modal-dialog {
  transform: none;
}

.qrcode-modal.modal.fade {
  transition: opacity 0.15s linear;
}

.qrcode-modal.modal.fade:not(.show) {
  opacity: 0;
}
/* END - QRCODE MODAL */

/* --------------- */
/* --------------- */

/* UTILITY */

/* change pointer on clickable icons or images without button or anchor */
.click-pointer:hover {
  cursor: pointer;
}

/* bootstrap did not have a max height. */
/* use to keep modal image contained on page if full screen and landscape*/
.max-vh-100 {
  max-height: 100vh;
}

.handwritten-text {
  font-family: "Caveat", cursive;
}

.drop-shadow {
  -webkit-filter: drop-shadow(10px 10px 10px #000000);
  filter: drop-shadow(10px 10px 10px #000000);
}
/* END - UTILITY */

/* --------------- */
/* --------------- */

/* MEDIA QUERY */
/* @media (orientation: portrait) {
} */

@media (orientation: landscape) {
  h1 {
    margin-bottom: 0.5rem;
  }

  #full-container {
    padding-bottom: 1rem;
  }

  /* .qrcode-box img {
    padding: 1rem;
    border: none;
    background-color: transparent;
    border-radius: 2rem;
  } */

  .install-popover {
    height: auto;
    width: 80vw;
    transform: translateY(-0.5rem);
    padding-top: 0.5rem;
    padding-bottom: 0rem;
  }

  /* .install-logo {
  } */

  .install-popover ol {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0rem;
  }

  .install-popover ol > li {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0px;
    margin-inline: 1rem;
    border-right: 1px solid rgba(0, 0, 0, 0.25);
  }

  .install-popover ol > li:last-child {
    flex-grow: 2;
    border-right: none;
    padding-right: 0rem;
  }

  .install-popover .install-logo {
    top: -1rem;
    left: -1rem;
    transform: translate(0%, 0%);
  }
}

@media only screen and (orientation: landscape) and (max-width: 450px) {
  .about-bg-img {
    background-image: radial-gradient(
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 1) 80%
      ),
      url("./assets/images/backgrounds/sitting-w-ball-bg-450x300.jpg");
    background-position-y: 40%;
  }
}

@media only screen and (orientation: landscape) and (min-width: 451px) {
  .about-bg-img {
    background-image: radial-gradient(
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 1) 80%
      ),
      url("./assets/images/backgrounds/sitting-w-ball-bg-750x500.jpg");
    background-position-y: 30%;
  }
}

@media only screen and (orientation: landscape) and (min-width: 751px) {
  .about-bg-img {
    background-image: radial-gradient(
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 1) 80%
      ),
      url("./assets/images/backgrounds/sitting-w-ball-bg-1125x750.jpg");
    background-position-y: 30%;
  }
}

@media only screen and (orientation: landscape) and (min-width: 1126px) {
  .about-bg-img {
    background-image: radial-gradient(
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 1) 80%
      ),
      url("./assets/images/backgrounds/sitting-w-ball-bg-1500x1000.jpg");
    background-position-y: 30%;
  }
}

/* smallest portrait image is set by default */
@media only screen and (orientation: portrait) and (min-width: 535px) {
  .about-bg-img {
    background-image: radial-gradient(
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 1) 80%
      ),
      url("./assets/images/backgrounds/sitting-w-ball-vert-bg-800x1200.jpg");
    /* background-position-y: center; */
  }
}

@media only screen and (orientation: portrait) and (min-width: 801px) {
  .about-bg-img {
    background-image: radial-gradient(
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 1) 80%
      ),
      url("./assets/images/backgrounds/sitting-w-ball-vert-bg-1067x1600.jpg");
    /* background-position-y: center; */
  }
}
